.crumbs {
  margin-top: calc(var(--space-l) * -1);

  ul {
    list-style: none;
    display: flex;
    gap: var(--space-3xs);
    padding: 0;
  }

  li {
    display: flex;
    gap: var(--space-3xs);
  }

  a {
    @include size-label(400, 'medium');
    color: var(--blue-100);
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }

  li:last-child {
    a {
      pointer-events: none;
      text-decoration: none;
      color: var(--mono-black);
    }
  }
}
