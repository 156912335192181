// Scss vars for Mq based on 16px
// $mobile: 25em; // 400px
// $tablet: 48em; // 768px
// $desktop: 64em; // 1024px
$root-breakpoints: (
    'sm': 600px,
    'md': 960px,
    'lg': 1200px
);

// Media query for elements above a set breakpoint value
@mixin bp-min($breakpoint) {
    @if map-has-key($root-breakpoints, $breakpoint) {
        $breakpoint-value: map-get($root-breakpoints, $breakpoint);

        @media (min-width: $breakpoint-value) {
            @content;
        }
    } @else {
        @warn 'Invalid breakpoint: #{$breakpoint}.';
    }
}

// Media query for elements below a set breakpoint value
@mixin bp-max($breakpoint) {
    @if map-has-key($root-breakpoints, $breakpoint) {
        $breakpoint-value: map-get($root-breakpoints, $breakpoint);

        @media (max-width: ($breakpoint-value - 1)) {
            @content;
        }
    } @else {
        @warn 'Invalid breakpoint: #{$breakpoint}.';
    }
}

// Media query for elements between two set breakpoint values
@mixin bp-min-max($lower, $upper) {
    @if map-has-key($root-breakpoints, $lower) and
        map-has-key($root-breakpoints, $upper)
    {
        $lower-breakpoint: map-get($root-breakpoints, $lower);
        $upper-breakpoint: map-get($root-breakpoints, $upper);

        @media (min-width: $lower-breakpoint) and (max-width: ($upper-breakpoint - 1)) {
            @content;
        }
    } @else {
        @if (map-has-key($root-breakpoints, $lower) == false) {
            @warn 'Your lower breakpoint was invalid: #{$lower}.';
        }

        @if (map-has-key($root-breakpoints, $upper) == false) {
            @warn 'Your upper breakpoint was invalid: #{$upper}.';
        }
    }
}
