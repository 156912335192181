body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: var(--font);
  @include size-paragraph(600, 'medium');
  background-color: var(--yellow-10);
  color: black;
}

.main {
  padding: var(--space-3xl) 0 90px;

  &--login-screen {
    padding: 0;
  }
}
