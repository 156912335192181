.task-metric {
  --metric-bar-path: var(--blue-100);

  display: flex;
  align-items: center;
  gap: var(--space-m);

  &__bar {
    position: relative;

    & > span {
      display: flex;
      align-items: center;
      position: absolute;
      top: calc(50% - 2px);
      left: calc(50% + 4px);
      transform: translate(-50%, -50%);
      color: var(--metric-bar-path, var(--blue-100));

      font-size: 32px;
      font-style: normal;
      font-weight: 500;
      line-height: 32px; /* 100% */

      & > span {
        color: var(--metric-bar-path, var(--blue-100));

        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px; /* 100% */
      }
    }
  }

  &__bar-path {
    stroke: var(--metric-bar-path, var(--blue-100));
    stroke-dasharray: 100%;

    transition: stroke-dashoffset 0.3s var(--ease-out-100);
  }

  &__labels {
    display: flex;
    flex-direction: column;
    gap: var(--space-3xs);
  }

  &__sub {
    display: flex;
    color: var(--mono-black);

    .icon {
      color: var(--red-100);
    }

    &.up {
      .icon {
        color: var(--green-80);
      }
    }
  }

  &.green {
    --metric-bar-path: var(--green-80);
  }

  &.blue {
    --metric-bar-path: var(--blue-100);
  }
}

.today {
  @include size-paragraph(300, 'regular');
  color: var(--mono-grey);
  position: absolute;
  right: 8px;
  margin: 0;
  bottom: 0;
  transform: translateY(-4px);
}
