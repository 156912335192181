.card {
  $this: &;
  cursor: pointer;
  position: relative;
  color: var(--mono-white);
  border-color: var(--c-grey-300);
  background-color: var(--blue-100);

  border-radius: var(--radius);
  display: flex;
  flex-direction: column;

  width: 100%;
  height: 100%;

  transition: background-color 0.2s var(--easing-400);

  &:hover {
    background-color: var(--blue-120);
  }

  &:active {
    background-color: var(--blue-140);
  }

  @supports (aspect-ratio: 1) {
    aspect-ratio: 1;
  }

  &__header {
    display: flex;
    padding: var(--space-xs) var(--space-s);
    justify-content: space-between;
    align-items: center;
    position: absolute;
    width: 100%;
  }

  &__inner {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-start;
    padding: var(--space-s) var(--space-s) var(--space-xl) var(--space-s);

    form {
      display: flex;
      flex-direction: column;
    }
  }

  &__title {
    @include size-headline(400, 'heavy');
    margin-top: var(--space-xs);
  }

  &__overline {
    @include size-subheadline(400, 'medium');
    color: var(--blue-20);
    margin-top: var(--space-2xs);
  }

  &__created {
    @include size-paragraph(300, 'regular');
    color: var(--c-grey-500);
  }

  // &--active {
  border-color: var(--c-grey-300);
  background-color: var(--blue-100);

  &:hover {
    border-color: var(--mono-black);
    box-shadow: var(--shadow-50);
  }

  &:active {
    border-color: var(--mono-black);
    box-shadow: none;
  }
  // }

  // &--empty {
  //     border-color: var(--c-grey-200);
  //     background-color: var(--c-grey-100);

  //     &:hover {
  //         border-color: var(--c-grey-500);
  //         box-shadow: var(--shadow-50);
  //     }
  // }
}
