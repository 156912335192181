.grid {
  &__container {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-gap: var(--grid-gap, var(--space-l));
    position: relative;
  }
  &__col {
    --grid-from: var(--jsx-sm-col-from);
    --grid-to: var(--jsx-sm-col-to);
    grid-column: var(--grid-from, 1) / var(--grid-to, -1);

    @include bp-min-max('md', 'lg') {
      --grid-from: var(--jsx-md-col-from, var(--jsx-sm-col-from));
      --grid-to: var(--jsx-md-col-to, var(--jsx-sm-col-to));
    }

    @include bp-min('lg') {
      --grid-from: var(
        --jsx-lg-col-from,
        var(--jsx-md-col-from, var(--jsx-sm-col-from))
      );
      --grid-to: var(
        --jsx-lg-col-to,
        var(--jsx-md-col-to, var(--jsx-sm-col-to))
      );
    }
  }

  &__col-span {
    --grid-span: var(--jsx-sm-col-span);
    grid-column: span var(--grid-span);

    @include bp-min-max('md', 'lg') {
      --grid-span: var(--jsx-md-col-span, var(--jsx-sm-col-span));
    }

    @include bp-min('lg') {
      --grid-span: var(
        --jsx-lg-col-span,
        var(--jsx-md-col-span, var(--jsx-sm-col-span))
      );
    }
  }
}

// Haims grid - maybe better as a mixin utility :thinking_face:

@mixin ugrid($cols: 12, $gap: 32px) {
  display: grid;
  grid-template-columns: repeat($cols, 1fr);
  gap: $gap;
}
.u-grid {
  @include ugrid(12, '32px');
}

// Auto grid option
.u-auto-grid {
  --grid-minmax: 310px;
  display: grid;
  grid-template-columns: repeat(
    auto-fill,
    minmax(var(--grid-minmax, 1fr), 1fr)
  );
  grid-gap: var(--grid-gap, var(--space-l));
}

// .u-auto-grid > * {
//     max-width: 25rem;
//     margin-left: auto;
//     margin-right: auto;
// }
