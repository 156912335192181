.section {
  &__head {
    & + * {
      margin-top: var(--space-2xl);
    }
  }
  &__head-inner {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: var(--space-m);

    @include bp-min('sm') {
      flex-direction: row;
      align-items: center;
    }
  }

  &__heading-wrap {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: var(--space-xs);
  }

  &__title {
    @include size-headline(600, 'heavy');
  }

  &__intro {
    @include size-paragraph(500, 'regular');
  }

  &__utility {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: var(--space-m);
    flex-shrink: 0;

    @include bp-min('sm') {
      margin-left: auto;
    }
  }
}
