.controls {
  position: relative;
  padding: var(--space-2xs);
  display: flex;
  align-items: center;
  justify-content: center;

  color: blue;

  &__button {
    appearance: none;
    border: none;
    padding: 2px var(--space-2xs);
    width: 100%;
    border-radius: 2px;
    text-align: left;
    cursor: pointer;
    background-color: transparent;

    &:hover {
      background-color: #e6e6e6;
    }
  }

  &__dialog {
    position: absolute;
    z-index: var(--z-dialog);
    top: 0;
    left: calc(-100% - 10px);
    background-color: #ffffff;
    border: 1px solid #e6e6e6;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 4px;
    padding: var(--space-2xs) 0;
    min-width: 1var (--space-m);
    flex-direction: column;
    align-items: flex-start;
    display: none;

    &.open {
      display: flex;
    }
  }
}
