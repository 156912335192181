@font-face {
  font-family: 'SuisseIntl';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url('../../assets/font/SuisseIntl-Regular-WebS.woff2') format('woff2'),
    url('../../assets/font/SuisseIntl-Regular-WebS.woff') format('woff');
}

@font-face {
  font-family: 'SuisseIntl';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url('../../assets/font/SuisseIntl-Medium-WebS.woff2') format('woff2'),
    url('../../assets/font/SuisseIntl-Medium-WebS.woff') format('woff');
}

@font-face {
  font-family: 'SuisseIntl';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url('../../assets/font/SuisseIntl-SemiBold-WebS.woff2') format('woff2'),
    url('../../assets/font/SuisseIntl-SemiBold-WebS.woff') format('woff');
}

:root {
  // Typography
  font-size: 62.5%;
  --font: 'SuisseIntl', sans-serif;
  --heavy: 600;
  --medium: 500;
  --regular: 400;
}

// Heading 400 - 600
@mixin size-headline($size: 400, $weight: medium) {
  font-weight: var(-- + $weight);

  @if $size == 600 {
    font-size: 4rem;
    line-height: 110%;
    letter-spacing: -0.7px;
  } @else if $size == 500 {
    font-size: 3.2rem;
    line-height: 112%;
    letter-spacing: -0.5px;
  } @else if $size == 400 {
    font-size: 2.4rem;
    line-height: 117%;
    letter-spacing: -0.25px;
  } @else {
    @error "Property: '#{$size}' must be one of [ 400, 500, 600 ]";
  }

  @if ($weight != regular) and ($weight != medium) and ($weight != heavy) {
    @error "Property '#{$weight}' must be either 'heavy', 'medium' or 'regular'";
  }
}

// Subheading 400
@mixin size-subheadline($size: 400, $weight: medium) {
  font-weight: var(-- + $weight);
  line-height: 1.6rem;
  text-transform: uppercase;

  @if $size == 400 {
    font-size: 1.4rem;
    letter-spacing: 1.4px;
  } @else if $size == 300 {
    font-size: 1.2rem;
    letter-spacing: 1.44px;
  } @else {
    @error "Property: '#{$size}' must be [ 300, 400 ]";
  }

  @if ($weight != medium) and ($weight != heavy) {
    @error "Property '#{$weight}' must be 'medium' or 'heavy'";
  }
}

// Paragraph 300 - 700
@mixin size-paragraph($size: 400, $weight: medium) {
  font-weight: var(-- + $weight);

  @if $size == 700 {
    font-size: 2.1rem;
    line-height: 133%;
    letter-spacing: -0.2px;
  } @else if $size == 600 {
    font-size: 1.8rem;
    line-height: 133%;
    letter-spacing: -0.18px;
  } @else if $size == 500 {
    font-size: 1.6rem;
    line-height: 150%;
    letter-spacing: -0.16px;
  } @else if $size == 400 {
    font-size: 1.4rem;
    line-height: 143%;
    letter-spacing: -0.05px;
  } @else if $size == 300 {
    font-size: 1.2rem;
    line-height: 133%;
    letter-spacing: 0.18px;
  } @else {
    @error "Property: '#{$size}' must be one of [ 300, 400, 500, 600, 700 ]";
  }

  @if ($weight != regular) and ($weight != medium) and ($weight != heavy) {
    @error "Property '#{$weight}' must be either 'heavy', 'medium' or 'regular'";
  }
}

// Button 300 - 600
@mixin size-button($size: 400, $weight: medium) {
  font-weight: var(-- + $weight);
  line-height: 100%;

  @if $size == 600 {
    font-size: 1.8rem;
    letter-spacing: -0.18px;
  } @else if $size == 500 {
    font-size: 1.6rem;
    letter-spacing: -0.16px;
  } @else if $size == 400 {
    font-size: 1.4rem;
    letter-spacing: -0.05px;
  } @else if $size == 300 {
    font-size: 1.2rem;
    letter-spacing: 0.16px;
  } @else {
    @error "Property: '#{$size}' must be one of [ 300, 400, 500, 600 ]";
  }

  @if ($weight != medium) {
    @error "Property '#{$weight}' must be 'medium'";
  }
}

@mixin button-reset() {
  appearance: none;
  display: flex;
  align-items: center;
  justify-content: center;
  color: currentColor;
  background-color: transparent;
  border-radius: 200px;
  border: none;
  padding: 0;
  cursor: pointer;
}

// Label 300 - 600
@mixin size-label($size: 400, $weight: medium) {
  font-weight: var(-- + $weight);
  line-height: 100%;

  @if $size == 600 {
    font-size: 18px;
    letter-spacing: -0.18px;
  } @else if $size == 500 {
    font-size: 1.6rem;
    letter-spacing: -0.16px;
  } @else if $size == 400 {
    font-size: 1.4rem;
    letter-spacing: -0.05px;
  } @else if $size == 300 {
    font-size: 1.2rem;
    letter-spacing: 0.16px;
  } @else {
    @error "Property: '#{$size}' must be one of [ 300, 400, 500, 600 ]";
  }

  @if ($weight != medium) {
    @error "Property '#{$weight}' must be 'medium'";
  }
}

// Input 400
@mixin size-input($size: 400, $weight: medium) {
  font-weight: var(-- + $weight);
  --medium: 500;

  @if $size == 400 {
    font-size: 1.4rem;
    line-height: 114%;
    letter-spacing: -0.05px;
  } @else {
    @error "Property: '#{$size}' must be [ 400 ]";
  }

  @if ($weight != medium) {
    @error "Property '#{$weight}' must be 'medium'";
  }
}
