/* Works on Firefox */
* {
    scrollbar-width: thin;
    scrollbar-color: var(--c-grey-800) var(--c-grey-200);
}

/* Works on Chrome, Edge, and Safari */
*::-webkit-scrollbar {
    width: 0.8rem;
    height: 0.8rem;
}

*::-webkit-scrollbar-track {
    background: var(--c-grey-200);
}

*::-webkit-scrollbar-thumb {
    background-color: var(--c-grey-800);
    border-radius: 0;
    border: 0 solid var(--c-grey-200);
}
