.avatar-icon {
  --a-bg: var(--orange-100);
  --a-fg: var(--orange-40);

  border-radius: var(--space-xl);

  &:hover {
    --a-bg: var(--orange-120);
    --a-fg: var(--orange-80);
  }

  &:active {
    --a-bg: var(--orange-120);
    --a-fg: var(--orange-80);
    border: 1px solid var(--blue-100);
  }

  [data-state='open'] & {
    border: 2px solid var(--blue-100);
  }

  &__bg {
    fill: var(--a-bg);
    transition: fill 0.3s var(--easing-400);
  }

  &__fg {
    fill: var(--a-fg);
    transition: fill 0.3s var(--easing-400);
  }
}
