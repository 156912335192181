.add-task {
  display: flex;
  gap: var(--space-2xs);
  width: 100%;

  input {
    width: 100%;
  }

  &__remove {
    @include button-reset();
  }
}
