[data-radix-popper-content-wrapper] {
  margin-top: 6px;
  //filter: drop-shadow(0px -1.5px 0px var(--mono-light-grey));
}

.popover__content {
  position: relative;
  z-index: var(--z-popover);
  border: 1px solid var(--mono-light-grey);
  border-radius: var(--radius);
  min-width: 18.9rem;
  background-color: var(--mono-white);
  filter: drop-shadow(var(--shadow-50));
}

.popover__list {
  padding: var(--space-3xs) 0;
}

.popover__link {
  @include size-paragraph(400, 'regular');
  display: block;
  padding: var(--space-xs);
  text-decoration: none;
  color: var(--mono-black);
  background-color: var(--mono-white);
  transition: background-color 0.3s var(--easing-400);
  cursor: pointer;

  &:hover,
  &:focus-visible {
    background-color: var(--mono-pale-grey);
  }
}

.popover__arrow {
  transform: translate(0, 0);
  fill: var(--mono-white);
  stroke-linecap: round;
  visibility: visible;
}

// .avatar {
//   display: flex;
//   flex-direction: column;
//   gap: var(--space-3xs);
//   padding: var(--space-xs);
//   border-bottom: 1px solid var(--c-grey-600);
// }
