.verbatim-detail {
  $this: &;

  background-color: var(--mono-white);
  border: 1px solid var(--mono-dark-eggshell);
  border-radius: var(--radius);
  padding: var(--space-l);
  height: 100%;

  &:hover {
    background-color: var(--mono-extra-pale-grey);
    border-color: var(--mono-light-grey);

    #{$this}__number,
    #{$this}__sub span,
    .progress__label {
      color: var(--mono-black);
    }
  }


  &__title {
    @include size-paragraph(600, 'medium');
    align-items: baseline;
    color: var(--mono-black);
  }

  &__sub {
    margin-top: var(--space-3xs);
    display: flex;
    align-items: center;

    span {
      @include size-subheadline(300, 'medium');
      color: var(--mono-grey);
    }
  }

  &__col {
    grid-column: span 3;
    padding: calc(var(--space-s) - 2px) var(--space-l);
  }
}
