.confidence {
  $this: &;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: var(--space-2xs) var(--space-s);
  border: 1px solid var(--green-100);
  border-radius: var(--space-xl);
  background-color: transparent;

  &__label {
    @include size-paragraph(500, 'medium');
    display: flex;
    align-items: center;
    gap: var(--space-2xs);
    color: var(--mono-black);
    cursor: default;
  }

  &.more-data-needed {
    border-color: var(--orange-40);

    #{$this}__label {
      color: var(--orange-140);
    }
  }
}
