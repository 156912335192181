.tabs {
  border-top: 1px solid var(--c-grey-200);
  border-bottom: 1px solid var(--c-grey-200);

  &__container {
    display: flex;
    list-style: none;
    gap: var(--space-xl);
    margin: 0;
    padding: 0 var(--site-gutter);
  }

  li {
    position: relative;
    padding: 22px 0;
  }
}

.tab {
  appearance: none;
  border: none;
  padding: 0;
  background-color: transparent;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: var(--space-xs);
  color: #000000;

  &__indicator {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 2px;
    background-color: currentColor;
  }
}

.tab__content {
  &[aria-hidden='false'] {
    @include visually-hidden();
  }

  // &--open {
  //     @include visiually-visible();
  // }
}
