.load-wrap {
  position: absolute;
  width: 100%;
  pointer-events: none;
}

.bars {
  display: flex;
  align-items: flex-end;
  gap: var(--space-2xs);
  height: 5.6rem;
  width: 5.8rem;
  margin: 200px auto;
}

.bar {
  height: 5.6rem;
  width: 1.4rem;
  border-radius: var(--radius);
  animation: barOne 1s cubic-bezier(0.49, 0, 0.33, 1) infinite;

  &:nth-child(1) {
    background-color: var(--orange-100);
  }

  &:nth-child(2) {
    background-color: var(--purple-100);
    animation-delay: 0.2s;
  }

  &:nth-child(3) {
    background-color: var(--yellow-100);
    animation-delay: 0.4s;
  }
}

@keyframes barOne {
  0%,
  100% {
    height: 5.6rem;
  }

  55% {
    height: 3.2rem;
  }
}
