.form {
  display: flex;
  flex-direction: column;

  label {
    display: flex;
    flex-direction: column;

    + & {
      margin-top: var(--space-2xs);
    }
  }
}

label {
  @include size-label(500, 'medium');

  input {
    margin-top: var(--space-2xs);
  }
}

input {
  @include size-input(400, 'medium');
  padding: 11px var(--space-xs);
  border-radius: var(--radius);
  color: var(--mono-black);

  &::placeholder {
    color: var(--mono-black);
  }
}
