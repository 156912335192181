[data-reach-dialog-overlay] {
  @include ugrid(12, var(--space-m));
  background: hsla(0, 0%, 0%, 0.5);
  cursor: pointer;
  backdrop-filter: blur(var(--space-3xs));
  z-index: var(--z-blind);
}

[data-reach-dialog-content] {
  grid-column: 2 / 12;
  position: relative;
  border-radius: calc(var(--radius) * 2);
  padding: 50px 40px;
  width: 100%;
  cursor: auto;
  z-index: var(--z-modal);

  @include bp-min('md') {
    grid-column: 3 / 11;
  }

  .close-button {
    @include button-reset();
    position: absolute;
    top: var(--space-s);
    right: var(--space-s);
  }
}
