*::selection {
  color: var(--mono-black);
  background-color: var(--yellow-80);
}

*:focus-visible {
  outline-color: var(--purple-100);
  outline-width: 2px;
  outline-style: solid;
  outline-offset: 0.3rem;
}

input:focus-visible {
  outline-color: var(--purple-100);
  outline-width: 4px;
  outline-style: solid;
  outline-offset: 0px;
}
