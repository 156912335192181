.button {
  appearance: none;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: var(--space-3xs);
  color: var(--color, var(--mono-white));
  background-color: var(--background, var(--mono-black));
  border: 1px solid var(--border, var(--mono-dark-eggshell));
  border-radius: var(--radius);
  text-decoration: none;
  cursor: pointer;
  transition: color 0.3s var(--easing-400),
    background-color 0.3s var(--easing-400), border-color 0.3s var(--easing-400);

  // Size
  &--max {
    width: 100%;
    @include size-subheadline(300, 'medium');
    padding: var(--space-m) var(--space-s);
    gap: var(--space-2xs);
  }
  
  &--large {
    @include size-subheadline(300, 'medium');
    padding: var(--space-m) var(--space-s);
    gap: var(--space-2xs);
  }

  &--medium {
    @include size-button(400);
    padding: var(--space-xs) var(--space-s);
  }

  &--small {
    @include size-button(300);
    padding: var(--space-2xs) var(--space-xs);
  }

  // Shape
  &--circle {
    --radius: 100%;
    @include size-label(400);
    width: 3.2rem;
    padding: 0;
    flex-shrink: 0;

    @supports (aspect-ratio: 1) {
      aspect-ratio: 1;
    }
  }

  &--pill {
    --radius: 4.8rem;
    min-width: 7.4rem;
  }

  // Color
  &--primary {
    --color: var(--mono-white);
    --background: var(--blue-100);
    --border: var(--blue-100);

    &:hover,
    &:focus {
      --background: var(--blue-120);
      box-shadow: var(--shadow-50);
    }

    &:active {
      --background: var(--blue-140);
    }
  }

  &--secondary {
    --color: var(--mono-black);
    --background: var(--mono-white);
    --border: var(--mono-dark-eggshell);

    &:hover {
      --border: var(--mono-light-grey);
      --background: var(--mono-extra-pale-grey);
      box-shadow: var(--shadow-50);
    }

    &[data-state='open'] {
      --color: var(--blue-100);
      --border: var(--blue-100);
      --background: var(--mono-white);
    }
  }
}

.button-loader {
  width: 1.4rem;
  height: 1.4rem;
  border-radius: 50%;
  border: 2px solid;
  border-color: var(--blue-60);
  border-right-color: var(--mono-white);
  animation: s2 1s infinite linear;
}
@keyframes s2 {
  to {
    transform: rotate(1turn);
  }
}
