.filter {
  background-color: var(--c-grey-200);
  border-radius: var(--radius);
  padding: var(--space-3xs);

  ul {
    display: flex;
    list-style: none;
    margin: 0;
    padding: 0;
  }

  li {
    position: relative;
    padding: 0;
  }

  &__button {
    @include button-reset();
    @include size-label();
    position: relative;
    z-index: 1;
    padding: var(--space-2xs) var(--space-m);
    position: relative;
  }

  &__indicator {
    position: absolute;
    z-index: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    width: 100%;
    height: 100%;
    background-color: var(--c-primary-white);
    border-radius: var(--radius);
  }
}
