.login-screen {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  place-content: center;
  height: 100vh;
  width: 100%;
  overflow: hidden;

  &__inner {
    width: 100%;
    max-width: 48rem;
    text-align: center;
    grid-column: 1;
    grid-row: 1;
    align-self: center;
    justify-self: center;
  }

  &__title {
    @include size-headline(600, 'heavy');
  }

  &__intro {
    @include size-paragraph(500, 'regular');
  }

  &__success {
    @include size-label(500, 'medium');
    color: var(--green-100);
    display: flex;
    gap: var(--space-2xs);
    align-items: center;
    justify-content: center;
  }

  &__input-wrap {
    position: relative;

    .input input {
      padding-right: 9rem;
      margin-bottom: var(--space-xs);
    }

    .button {
      top: 1rem;
      right: 1rem;
    }

    .cancel_button {
      display: block;
      margin-top: var(--space-xs);
    }
  }

  &__pattern {
    grid-column: 1;
    grid-row: 1;
    align-self: center;
    justify-self: center;
    width: 100%;
    height: 100vh;
    position: relative;
    pointer-events: none;

    svg.intentful-pattern {
      position: absolute;
      object-fit: cover;
      object-position: center center;
      width: auto;
      height: 100vh;
      min-width: 140rem;
      top: 0;
      left: 50%;
      transform: translateX(-50%);

      .path {
        stroke-dasharray: 100%;
        stroke-dashoffset: -100%;
        animation: dash 1s var(--ease-out-100) forwards;
        @for $i from 15 through 31 {
          @each $d in $i {
            $d: -0.9 + (($d - 5) * 0.1);
            &:nth-of-type(#{$i}) {
              animation-delay: #{$d} + 's';
            }
          }
        }
      }

      .rect {
        opacity: 0;
        animation: dot 0.6s var(--easing-400) forwards;
        @for $i from 1 through 20 {
          @each $d in $i {
            $d: 1 + (($d - 3) * 0.1);
            &:nth-of-type(#{$i}) {
              animation-delay: #{$d} + 's';
            }
          }
        }
      }
    }
  }
}

@keyframes dash {
  0% {
    stroke-dashoffset: -100%;
  }
  100% {
    stroke-dashoffset: 0;
  }
}

@keyframes dot {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
