.flow > * + * {
  margin-top: var(--flow-space, 1em);
}

.flow-3xs {
  --flow-space: var(--space-3xs);
}

.flow-xs {
  --flow-space: var(--space-xs);
}

.flow-s {
  --flow-space: var(--space-s);
}

.flow-m {
  --flow-space: var(--space-m);
}

.flow-l {
  --flow-space: var(--space-l);
}

.flow-xl {
  --flow-space: var(--space-xl);
}

.flow-2xl {
  --flow-space: var(--space-2xl);
}

.flow-3xl {
  --flow-space: var(--space-3xl);
}
