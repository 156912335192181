.input {
  display: flex;
  flex-direction: column;
  gap: var(--space-2xs);
  width: 100%;

  input {
    @include size-paragraph(500, 'regular');
    color: var(--mono-grey);
    width: 100%;
    padding: var(--space-s);
    border: 1px solid var(--mono-light-grey);
    border-radius: var(--radius);

    &::placeholder {
      color: var(--mono-grey);
    }
  }

  &__helper,
  &__validation {
    @include size-paragraph(300, 'regular');
    display: flex;
    align-items: center;
    gap: var(--space-3xs);
  }
}
