.select {
  .icon--chevron-down {
    transform: rotate(0deg);
    transition: transform 0.3s var(--easing-400);
  }
  &[data-state='open'] {
    .icon--chevron-down {
      transform: rotate(180deg);
    }
  }

  &__content {
    padding: var(--space-3xs) 0;
    position: relative;
    border: 1px solid var(--mono-light-grey);
    border-radius: var(--radius);
    min-width: 18.9rem;
    background-color: var(--mono-white);
    filter: drop-shadow(var(--shadow-50));
  }

  &__label {
    @include visually-hidden();
  }

  &__item {
    @include size-paragraph(400, 'regular');
    display: block;
    padding: var(--space-xs);
    text-decoration: none;
    color: var(--mono-black);
    background-color: var(--mono-white);
    transition: background-color 0.3s var(--easing-400);
    cursor: pointer;

    &:hover,
    &:focus-visible {
      background-color: var(--mono-pale-grey);
      outline: none;
    }
  }
}
