.task-table {
  $this: &;

  background-color: var(--mono-white);
  border: 1px solid var(--mono-dark-eggshell);
  border-radius: var(--radius);

  &__titles {
    @include ugrid(12, 0);
  }

  &__title {
    @include button-reset();
    @include size-subheadline(300, 'medium');
    line-height: inherit;
    gap: var(--space-3xs);
    padding: var(--space-s) var(--space-l);
    border-radius: 0px;
    width: 100%;
    justify-content: flex-start;
    border-bottom: 1px solid var(--mono-dark-eggshell);
    transition: background-color 0.3s var(--easing-400);

    &:hover {
      background-color: var(--mono-extra-pale-grey);
      border-color: var(--mono-light-grey);
    }

    &.task-active {
      color: var(--blue-100);
    }

    .icon {
      margin-top: -3px;
    }
  }

  &__list {
    li {
      &:last-child {
        .task-summary {
          border-radius: 0 0 var(--space-2xs) var(--space-2xs);
        }
      }
    }
  }

  &__no-data {
    padding: var(--space-s) var(--space-l);
    text-align: center;
  }

  &__loading {
    position: relative;
    padding: var(--space-xl) var(--space-l);

    .load-wrap {
      position: relative;

      .bars {
        margin: 0 auto;
      }
    }
  }

  &__col {
    grid-column: span 3;

    #{$this}__title {
      border-right: 1px solid var(--mono-dark-eggshell);
    }
    &:first-child {
      grid-column: span 6;
      display: flex;

      #{$this}__title {
        border-radius: var(--radius) 0 0 0;
      }
    }

    &:last-child {
      #{$this}__title {
        border-right: none;
        border-radius: 0 var(--radius) 0 0;
      }
    }
  }
}
