.pill {
  $this: &;
  padding: 6px var(--space-2xs);
  border-radius: var(--radius);
  background-color: var(--c-grey-300);

  &__label {
    @include size-label(300);
    color: var(--c-grey-900);
    text-transform: uppercase;
    cursor: default;
  }

  &--active {
    background-color: var(--c-grey-600);

    #{$this}__label {
      color: var(--mono-white);
    }
  }
}
