.add-tasklist {
  $this: &;
  border-top: 1px solid var(--c-grey-400);
  padding-top: var(--space-l);

  &__title {
    @include size-headline(400, 'heavy');

    & + #{$this}__helper {
      margin-top: var(--space-2xs);
    }
  }

  &__helper {
    @include size-paragraph(400, 'regular');
    color: var(--c-grey-600);
  }

  ul {
    list-style: none;
    padding: 0;
  }

  li + li {
    margin-top: var(--space-2xs);
  }
}
