.progress {
  $this: &;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: var(--space-3xs);

  &__wrap {
    position: relative;
    width: 100%;
    height: var(--space-xs);
    background-color: var(--p-track);
    border: 1px solid var(--p-border);
    border-radius: calc(var(--radius) * 5);
    margin: 0.6rem 0;
    transition: background-color 0.2s var(--easing-400),
      border-color 0.2s var(--easing-400);
  }

  &__label {
    @include size-subheadline(300, 'medium');
    color: var(--mono-grey);
  }

  &__bar {
    position: absolute;
    height: var(--space-xs);
    background-color: var(--p-bar);
    border-radius: calc(var(--radius) * 5);
    top: -0.1rem;
    left: -0.1rem;
    transition: width 0.3s var(--easing-400),
      background-color 0.3s var(--easing-400);
  }

  &--amazing {
    --p-bar: var(--green-120);
    --p-track: var(--green-20);
    --p-border: var(--green-60);
  }

  &--good {
    --p-bar: var(--green-100);
    --p-track: var(--green-20);
    --p-border: var(--green-60);
  }

  &--neutral {
    --p-bar: var(--yellow-100);
    --p-track: var(--yellow-20);
    --p-border: var(--yellow-80);
  }

  &--bad {
    --p-bar: var(--orange-100);
    --p-track: var(--orange-20);
    --p-border: var(--orange-60);
  }

  &--terrible {
    --p-bar: var(--red-100);
    --p-track: var(--red-20);
    --p-border: var(--red-60);
  }
}
