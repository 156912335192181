.alert {
  position: relative;
  padding: var(--space-m);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: var(--space-xs);
  width: 100%;
  border-radius: var(--radius);
  color: var(--mono-black);
  background-color: var(--background, var(--c-grey-200));

  &__controls {
    display: flex;
    align-items: center;
    gap: var(--space-2xs);
  }

  &__link {
    text-decoration: none;
  }

  &__mesaage {
    @include size-paragraph(500, 'regular');
    padding-right: var(--site-gutter);
  }

  &__close {
    @include button-reset();
    position: absolute;
    top: var(--space-m);
    right: var(--space-m);
    color: inherit;
  }

  &--info {
    --background: var(--c-grey-200);
  }
}
