:root {
  --mono-white: #ffffff;
  --mono-eggshell: #f3f1e6;
  --mono-dark-eggshell: #e0ddce;
  --mono-pale-grey: #ebebeb;
  --mono-extra-pale-grey: #f8f9fc;
  --mono-light-grey: #bbbbbb;
  --mono-grey: #767676;
  --mono-black: #0f0f0f;

  --blue-140: #153494;
  --blue-120: #1c45c5;
  --blue-100: #2356f6; // primary
  --blue-80: #4f78f8;
  --blue-60: #7b9afa;
  --blue-40: #a7bbfb;
  --blue-20: #d3ddfd;
  --blue-10: #e9eefe;

  --purple-140: #551780;
  --purple-120: #8925cc;
  --purple-100: #ab2eff; // primary
  --purple-80: #bc58ff;
  --purple-60: #cd82ff;
  --purple-40: #ddabff;
  --purple-20: #eed5ff;

  --red-140: #8b0b10;
  --red-120: #b90f15;
  --red-100: #e7131a; // primary
  --red-80: #ec4248;
  --red-60: #f17176;
  --red-40: #f5a1a3;
  --red-20: #fad0d1;

  --orange-140: #8e4901;
  --orange-120: #be6102;
  --orange-100: #ed7902; // primary
  --orange-80: #f19435;
  --orange-60: #f4af67;
  --orange-40: #f8c99a;
  --orange-20: #fcead7;

  --yellow-140: #98710a;
  --yellow-120: #ca970d;
  --yellow-100: #fdbd10; // primary
  --yellow-80: #fdca40;
  --yellow-60: #fed770;
  --yellow-40: #fee59f;
  --yellow-20: #fff4d9;
  --yellow-10: #fbf9f2;

  --green-140: #01520b;
  --green-120: #026d0e;
  --green-100: #028812; // primary
  --green-80: #35a041;
  --green-60: #67b871;
  --green-40: #9acfa0;
  --green-20: #cce7d0;

  // Transparent
  --c-transparent: rgba(0, 0, 0, 0.5);

  // Shadows
  --shadow-100: 0px 4px 8px rgba(0, 0, 0, 0.1);
  --shadow-60: 0px 0px 40px rgba(0, 0, 0, 0.2);
  --shadow-50: 0px 0px 20px rgba(0, 0, 0, 0.1);
}
