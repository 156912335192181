.icon {
    display: flex;
    align-items: center;
    justify-content: center;
    color: inherit;

    svg {
        fill: currentColor;
    }

    &--small {
        svg {
            width: 1.6rem;
            height: 1.6rem;
        }
    }

    &--medium {
        svg {
            width: 2.4rem;
            height: 2.4rem;
        }
    }

    &--large {
        svg {
            width: 4rem;
            height: 4rem;
        }
    }
}
