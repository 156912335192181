.tasklist-detail {
  $this: &;

  background-color: var(--mono-white);
  border: 1px solid var(--mono-dark-eggshell);
  border-radius: var(--radius);
  padding: var(--space-l);
  height: 100%;

  &__title {
    @include size-paragraph(500, 'heavy');

    & + #{$this}__list {
      margin-top: var(--space-s);
    }
  }

  &__list {
    display: flex;
    gap: var(--space-xl);
  }

  // &__legend-wrap {
  //   flex-basis: 60%;
  // }

  &__legend {
    display: flex;
    gap: var(--space-xs);
    align-items: center;
    border-bottom: 1px solid var(--mono-pale-grey);

    &:last-child {
      border-bottom: none;
    }
  }

  &__legend-dot {
    width: var(--space-xs);
    height: var(--space-xs);
    flex-shrink: 0;
    border-radius: 100%;
    background-color: var(--mono-light-grey);
  }

  &__chart {
    max-width: 220px;
  }

  &__item {
    @include size-paragraph(400, 'regular');
    display: flex;
    align-items: flex-start;
    padding: var(--space-2xs) 0;
    gap: var(--space-2xs);
  }

  // &__dt {
  //   @include size-paragraph(500, 'regular');
  //   flex-basis: 60%;
  // }

  // &__dd {
  //   flex-basis: 40%;
  // }

  &__button {
    @include button-reset();
    display: flex;
    align-items: center;
    transition: 0.3s color var(--ease-out-100);

    .icon {
      position: relative;
      top: 1.5px;
    }

    &:hover {
      color: var(--blue-100);
    }
  }
}
