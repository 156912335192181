.header {
  padding: var(--space-m) 0;

  // TODO: Do this properly!
  .container {
    display: flex;
    justify-content: space-between;
    gap: 90px;
  }

  &__utility {
    margin-left: auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    list-style: none;
    gap: var(--space-s);
  }
}
