.dlist {
  &__item {
    display: grid;
    grid-template-columns: 1fr 2fr;
    gap: var(--space-m);
    padding: var(--space-xs) 0;
    border-bottom: 1px solid var(--c-grey-400);
  }

  &__dt {
    @include size-paragraph(500, 'medium');
  }

  &__dd {
    @include size-paragraph(500, 'regular');
  }
}
