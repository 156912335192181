:root {
  --flow-space: var(--space-m);
  /* Fluid - spacing */
  --space-3xs: 0.4rem;
  --space-2xs: 0.8rem;
  --space-xs: 1.2rem;
  --space-s: 1.6rem;
  --space-m: 2rem;
  --space-l: 2.4rem;
  --space-xl: 3.2rem;
  --space-2xl: 4.8rem;
  --space-3xl: 6.4rem;

  // Stack
  --z-popover: 2;
  --z-dialog: 2;
  --z-blind: 3;
  --z-modal: 4;

  --radius: 0.8rem;

  --site-gutter: var(--space-3xl);

  --easing-400: cubic-bezier(0.25, 1, 0.5, 1);
  --ease-out-100: cubic-bezier(0.12, 0.09, 0, 1.02);
}
