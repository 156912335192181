.modal {
  &__overlay {
    @include ugrid(12, var(--space-m));
    background: hsla(0, 0%, 0%, 0.5);
    position: fixed;
    inset: 0;
    cursor: pointer;
    z-index: var(--z-blind);
    backdrop-filter: blur(4px);
    animation: overlayShow 0.5s cubic-bezier(0.16, 1, 0.3, 1);
  }

  &__content {
    grid-column: 2 / 12;
    position: relative;
    border-radius: calc(var(--radius) * 2);
    padding: 50px 40px;
    width: 100%;
    cursor: auto;
    z-index: var(--z-modal);
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-height: 85vh;
    background-color: var(--mono-white);
    animation: contentShow 0.5s cubic-bezier(0.16, 1, 0.3, 1);

    @include bp-min('md') {
      grid-column: 3 / 11;
    }
  }

  &__close {
    @include button-reset();
    position: absolute;
    top: var(--space-s);
    right: var(--space-s);
  }
}

@keyframes overlayShow {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes contentShow {
  from {
    opacity: 0;
    opacity: translate(-50%, -48%) scale(0.96);
  }
  to {
    opacity: 1;
    transform: translate(-50%, -50%) scale(1);
  }
}
