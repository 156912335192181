.task-summary {
  $this: &;

  @include ugrid(12, 0);
  text-decoration: none;
  align-items: center;
  color: var(--mono-black);
  transition: background-color 0.3s var(--easing-400);
  border-top: 0.5px solid transparent;
  border-bottom: 1px solid var(--mono-eggshell);

  &:hover {
    background-color: var(--mono-extra-pale-grey);
    border-color: var(--mono-light-grey);

    #{$this}__number,
    #{$this}__sub span,
    .progress__label {
      color: var(--mono-black);
    }
  }

  &__task {
    grid-column: span 6;
    display: flex;
    gap: var(--space-2xs);
    padding: calc(var(--space-s) - 2px) 0 calc(var(--space-s) - 2px)
      var(--space-l);
  }

  &__number {
    @include size-paragraph(600, 'medium');
    display: block;
    color: var(--mono-grey);
    width: var(--space-l);
    flex-shrink: 0;
    transition: color 0.3s var(--easing-400);
  }

  &__title {
    @include size-paragraph(600, 'medium');
    align-items: baseline;
    color: var(--mono-black);
  }

  &__sub {
    margin-top: var(--space-3xs);
    display: flex;
    align-items: center;

    span {
      @include size-subheadline(300, 'medium');
      color: var(--mono-grey);
    }
  }

  &__col {
    grid-column: span 3;
    padding: calc(var(--space-s) - 2px) var(--space-l);
  }
}
