.container {
  position: relative;
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;
  padding: 0 var(--space-xs);

  @include bp-min('md') {
    padding: 0 var(--site-gutter);
  }

  & .container {
    padding: 0;
  }
}

.flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.s-between {
  justify-content: space-between;
}

.full-height {
  height: 100vh;
}
