.question {
  --q-color: var(--c-grey-400);
  $this: &;
  padding: var(--space-l);
  border: 1px solid var(--q-color);
  border-left: var(--space-3xs) solid var(--q-color);
  border-radius: calc(var(--radius) * 2);

  & + & {
    margin-top: var(--space-l);
  }

  &__head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: var(--space-m);
  }

  &__title {
    @include size-headline(400, 'heavy');
    color: var(--q-color);
    text-overflow: ellipsis;
  }

  &__overline {
    @include size-subheadline(400, 'medium');
    color: var(--q-color);
  }

  &__toggle {
    @include button-reset();
    color: var(--q-color);
    transition: transform 0.3s var(--easing-400);
  }

  &__open {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }

  &__items {
    width: 100%;
  }

  // &__label {
  //     display: flex;
  //     flex-direction: column;
  //     width: 100%;
  // }

  &--disabled {
    --q-color: var(--c-grey-400);
  }

  &--open {
    --q-color: var(--mono-black);
    box-shadow: var(--shadow-100);

    #{$this}__toggle {
      transform: rotateZ(180deg);
    }

    &#{$this}--disabled {
      --q-color: var(--mono-black);
    }
  }

  &--saved {
    --q-color: var(--c-grey-600);
  }
}
